export const html = `
<h1>Come Work with Us</h1>
&nbsp;
<p>
    @@FULL_DOMAIN@@ is a fast growing visual search engine that partners with some of the biggest names in the industry.
    We offer online shoppers the best deals on a huge selection of product categories and we're constantly improving our
    technology to provide the best service the Internet has seen.
</p>
<p>We"re always on the lookout for ambitious, intelligent, fun people who are ready to leave their mark.</p>
<h3>POSITION NO. 57</h3>
<p>
    <strong>For our development center in Tel-Aviv, Israel.</strong>
</p>
<p>Our technology-driven internet company is looking to add top-notch people to our already great team.</p>
<p>We are seeking a highly motivated and experienced Web Developer to join our professional and talented R∓D team.</p>
<h4>Responsibilities</h4>
<ul>
    <li>Participate in building the system’s architecture as well as providing innovative and scalable solutions</li>
    <li>Design and implement new features</li>
    <li>Enhance and maintain our PHP web-based system facilitating MVC architecture</li>
    <li>Work in a team of experienced developers</li>
    <li>Work in Agile methodology (Scrum/Kanban/etc) and in LAMP environment</li>
    <li>Develop cross browser and cross platform (mobile) web sites</li>
</ul>
<h4>Requirements</h4>
<ul>
    <li>3-4 years of experience in PHP/.NET/Ruby/Python websites development</li>
    <li>At least 1 year of Experience in SQL/MySQL</li>
    <li>1-3 years of experience in HTML, JavaScript, Ajax, jQuery and CSS</li>
    <li>Experience in Object Oriented and design patterns programming</li>
    <li>Experience in CodeIgniter or any other MVC framework – an advantage</li>
    <li>B.Sc. or higher in Computer Science or similar degree – an advantage</li>
</ul>
&nbsp;<p>Please send your resume to jobs [at] @@FULL_DOMAIN@@.</p>
<h3>POSITION NO. 62</h3>
<p>
    <strong>For our office in NYC, New York.</strong>
</p>
<p>
    We are seeking a highly motivated, experienced internet sales manager to join us in taking our business to the next
    level.
</p>
<h4>Responsibilities</h4>
<ul>
    <li>Pursue, develop and prospect new business relationships</li>
    <li>Manage and develop existing relationships with current clients</li>
    <li>Manage an eight-digit annual invoiced revenue portfolio</li>
    <li>Negotiate commercial terms while utilizing analytical data</li>
    <li>
        Advance and establish new opportunities with existing relationships and define and report revenue opportunities
    </li>
</ul>
<h4>Requirements</h4>
<ul>
    <li>Native English speaker.</li>
    <li>5+ years of experience in sales / developing new markets</li>
    <li>3+ years of experience at a hi-tech company. Experience at an Internet company- advantage</li>
    <li>Academic degree</li>
    <li>Excellent analytical and relationship building skills as well as familiarity with web analytics tools</li>
</ul>
&nbsp;<p>Please send your resume to jobs [at] @@FULL_DOMAIN@@.</p>
`;