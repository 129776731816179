export const html =`
<h1>Frequently Asked Questions</h1>
&nbsp;
<p>We"ve gathered answered to the most popular questions we get asked about @@FULL_DOMAIN@@.</p>
<h3>What is @@FULL_DOMAIN@@?</h3>
<p>
    @@FULL_DOMAIN@@ is a visual product comparison search engine. Using the site, you get to find the best product that
    fits your needs at the best price – and you can do it all while enjoying beautiful images.
</p>
<h3>What kind of products and brands can I find here?</h3>
<p>
    Almost anything you want to shop is available through @@FULL_DOMAIN@@. Baby products, furniture, kitchen appliances,
    toys, home décor, small appliances, sporting goods, computers, shoes, clothing and apparel are only a few examples
    of the types of products you can find here.
</p>
<p>
    The same goes for brands. Search for established brands, like Fisher Price, Corelle and Nike, or for newer brands,
    like GoldieBlox.
</p>
<h3>What's the price range?</h3>
<p>
    We offer products for every budget. Use the left sidebar to filter the price range and you'll only see products that
    fit you. Under "Price", fill the "from $" and "to $" boxes, then click on "Go".
</p>
<h3>How do I find similar or cheaper products?</h3>
<p>Here's how to use the site and find the better and/or cheaper products we keep talking about.</p>
<ul>
    <li>
        Type a brand name or product at the search bar on the home page or at the top of every page. Then click the blue
        "search" button.
    </li>
    <li>Browse through the products on the page to find the one you like most.</li>
    <li>
        To narrow the search, mark only relevant categories in the left sidebar. The search will be automatically
        updated. You can also use the left sidebar to filter products by price range – or ask to shop only from nearby
        stores by typing your zip code and clicking on "go".
    </li>
    <li>
        Once you find a product you like, click on it and you'll be taken to the website of the store that sells it.
        There, you'll be able to read more information and even buy the product.
    </li>
    <li>
        Looking for alternatives? Instead of clicking on the product's name or image, place your cursor on the "Find
        Similar" button that's located on the product's image. A new window will open up, showing you several
        alternatives at better prices. If free shipping is available, we'll mention that as well.
    </li>
    <li>
        Clicking on a product's name or image will take you to the store that sells it in the noted price. There, you
        can read more information about the product and make your purchase.
    </li>
</ul>
<h3>I believe in buying local. Can I do that through your site?</h3>
<p>
    Absolutely. While the Internet enables you to buy from every corner of the country – the world, really – and we're
    more than happy to help you do that, we support your commitment to your local town or region. We also know that
    sometimes you need something delivered fast or the Free Shipping option , and that's often easier to do when you
    shop close by.
</p>
<p>
    To do that, enter your zip code at the top of the left sidebar and click on "Go". We'll only show you products that
    are available in your area.
</p>`;
