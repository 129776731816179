import React, { PureComponent } from 'react';
import { roundToNearest } from 'src/lib/utils';
import { connect } from 'react-redux';
import TrackingManager from 'src/lib/managers/TrackingManager';
import PerformanceManager from 'src/lib/managers/PerformanceManager';
import { INIT_SEARCH_TIMESTAMP, IS_PROD, PAGEVIEW_TIME } from 'src/lib/constants/general';
import { SEARCH, PREFIX_SLUG } from 'src/lib/constants/routes';
import Router from 'next/router';
import { setCurrentPage } from '../redux/modules/settings';
import TrackingReduxManager from '../managers/TrackingReduxManager';

export default function pageTracker(prefix = '') {
	return ComposedComponent => {
		@connect(dispatch => ({
			dispatch,
		}))
		class pageTrackerComponent extends PureComponent {
			componentDidMount() {
				const { dispatch } = this.props;
				const pathname = location.pathname;
				const cid = TrackingReduxManager.getEntry('cid');
				let name = pathname === '/' ? 'home' : `${pathname.substr(1).replace('/index.html', '')}`;
				dispatch(setCurrentPage(name));

				let initSearchTimestamp = null;
				let timeToPageView = null;
				if (Router && Router.router && (Router.router.route === SEARCH || Router.router.route === PREFIX_SLUG)) {
					initSearchTimestamp = PerformanceManager.get(INIT_SEARCH_TIMESTAMP);
					timeToPageView = initSearchTimestamp ? roundToNearest(Date.now() - initSearchTimestamp, 100) : null;
					PerformanceManager.set(PAGEVIEW_TIME, timeToPageView);
				}

				const eventValueString2 = (() => {
					if (!window.matchMedia) {
						return 'UNKNOWN';
					}

					return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'DARK' : 'LIGHT';
				})();

				const eventProps = {
					name: `${prefix}${name}`,
					action: 'pageview',
					eventValueInteger1: timeToPageView,
					eventValueString2,
					eventValueString11: document.referrer,
				};
				TrackingManager.trackEvent('pageview', eventProps);
				TrackingManager.postBingPixel();

				if (IS_PROD && +cid === 9480) {
					document
						.getElementsByTagName('body')[0]
						.insertAdjacentHTML(
							'afterend',
							`<img src="https://pixels.reignn.com/?datasource=ktest&event=UTMSession&pub_id=150764&sh_ing=1&domain=huntaprice.com&traffic_source=fb" height="1" width="1" style="display:none"></img>`
						);
				}
			}

			render() {
				const { ...rest } = this.props;
				return <ComposedComponent {...rest} />;
			}
		}

		return pageTrackerComponent;
	};
}
