import Head from 'next/head';
import React, { Fragment } from 'react';
import { PREFIX_SLUG, PREFIX, CATEGORY_PAGE } from 'src/lib/constants/routes';
import { useRouter } from 'next/router';
import { capitalize, seperateByComma } from 'src/lib/utils';
import QueryManager from 'src/lib/managers/QueryManager';
import TrackingManager from 'src/lib/managers/TrackingManager';
import { fetchLocal, storeLocal } from 'src/lib/managers/StorageManager';
import { IS_SERVICE_WORKER } from 'src/lib/constants/general';
import useWhoAmI from 'src/lib/hooks/useWhoAmI';
import useDictionary from 'src/lib/hooks/useDictionary';

let installedSent = false;

const Helmet = props => {
	const router = useRouter();
	const { query = {}, pathname = '' } = router;
	let { slug = '', q = '', categoryName = '' } = query;
	const { children, pageTitle = '' } = props;
	const { APP_NAME, isHap, FULL_DOMAIN = '' } = useWhoAmI();
	const dictionary = useDictionary();

	const fullDomain = FULL_DOMAIN.toLowerCase();

	if (Array.isArray(q)) {
		q = q[0];
	}

	let unslugKeyword = QueryManager.unslugify(slug) || q || QueryManager.unslugify(categoryName);

	let capitalizeKeyword = capitalize(unslugKeyword.replace(/\s+/g, ' '));
	capitalizeKeyword =
		pathname === PREFIX_SLUG || pathname === CATEGORY_PAGE
			? `${capitalizeKeyword}`
			: `${dictionary('Get the best deals')}`;

	const keywords = seperateByComma(unslugKeyword);

	if (typeof window !== 'undefined' && typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
		if (!IS_SERVICE_WORKER) {
			navigator.serviceWorker
				.getRegistrations()
				.then(function (registrations) {
					for (let registration of registrations) {
						if (registration) registration.unregister();
					}
				})
				.catch(function (err) {
					console.log('Service Worker registration failed: ', err);
				});
		}
		if (IS_SERVICE_WORKER) {
			navigator.serviceWorker.addEventListener('message', e => {
				if (!e || !e.data) return;
				const { stage, cacheVersion } = e.data;
				const version = (fetchLocal('sw') || {}).version;
				if (stage === 'SW-INSTALLED' && !installedSent) {
					installedSent = true;
					storeLocal('sw', { version: cacheVersion });
					TrackingManager.trackEvent('SW-INSTALL');
				}
				if (stage === 'VERSION' && version !== cacheVersion) {
					storeLocal('sw', { version: cacheVersion });
				}
			});

			navigator.serviceWorker
				.register(isHap ? '/hap-sw.js' : '/sw.js')
				.then(sw => {
					let lastOnlineState = navigator.onLine;
					console.log('swr', sw.active ? sw.active.state : false, lastOnlineState);
					const offlineChanger = () => {
						if (lastOnlineState !== navigator.onLine) location.reload();
					};
					window.addEventListener('online', offlineChanger);
					window.addEventListener('offline', offlineChanger);
				})
				.catch(console.error);
		}
	}

	const date = new Date();
	const monthNames = [
		dictionary('January'),
		dictionary('February'),
		dictionary('March'),
		dictionary('April'),
		dictionary('May'),
		dictionary('June'),
		dictionary('July'),
		dictionary('August'),
		dictionary('September'),
		dictionary('October'),
		dictionary('November'),
		dictionary('December'),
	];
	const month = monthNames[date.getMonth()];
	const year = date.getFullYear();
	let metaTitle = pageTitle || `${capitalizeKeyword} ${dictionary('Online | Up To 70% Off |')} ${month} ${year} ${dictionary('Deals')}`;
	//not in line 27 because metaTitle uses capitalizeKeyword
	if (pathname === PREFIX_SLUG || pathname === CATEGORY_PAGE) {
		capitalizeKeyword = `${dictionary('I searched for')} ${capitalizeKeyword}`;
		metaTitle = `Shop ${metaTitle}`;
	}

	const renderHeadIcons = () => {
		const faviconsFolder = isHap ? 'hap' : 'fs';
		return (
			<Fragment>
				<link rel="shortcut icon" href={`${isHap ? '/hap-favicon.ico' : '/favicon.ico'}`} />
				<link rel="manifest" href={`${isHap ? '/hap-manifest.json' : '/manifest.json'}`}></link>

				<link
					rel="apple-touch-icon-precomposed"
					sizes="57x57"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-57x57.png`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="114x114"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-114x114.png`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="72x72"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-72x72.png`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="144x144"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-144x144.png`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="60x60"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-60x60.png`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="120x120"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-120x120.png`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="76x76"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-76x76.png`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="152x152"
					href={`/shared/favicons/${faviconsFolder}/apple-touch-icon-152x152.png`}
				/>

				<link rel="icon" type="image/png" href={`/shared/favicons/${faviconsFolder}/favicon-196x196.png`} sizes="196x196" />
				<link rel="icon" type="image/png" href={`/shared/favicons/${faviconsFolder}/favicon-96x96.png`} sizes="96x96" />
				<link rel="icon" type="image/png" href={`/shared/favicons/${faviconsFolder}/favicon-32x32.png`} sizes="32x32" />
				<link rel="icon" type="image/png" href={`/shared/favicons/${faviconsFolder}/favicon-16x16.png`} sizes="16x16" />
				<link rel="icon" type="image/png" href={`/shared/favicons/${faviconsFolder}/favicon-128.png`} sizes="128x128" />

				<meta name="msapplication-TileImage" content={`/shared/favicons/${faviconsFolder}/mstile-144x144.png`} />
				<meta name="msapplication-square70x70logo" content={`/shared/favicons/${faviconsFolder}/mstile-70x70.png`} />
				<meta name="msapplication-square150x150logo" content={`/shared/favicons/${faviconsFolder}/mstile-150x150.png`} />
				<meta name="msapplication-wide310x150logo" content={`/shared/favicons/${faviconsFolder}/mstile-310x150.png`} />
				<meta name="msapplication-square310x310logo" content={`/shared/favicons/${faviconsFolder}/mstile-310x310.png`} />

				<link
					href={`/shared/images/splash/${faviconsFolder}/iphone5_splash.png`}
					media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/iphone6_splash.png`}
					media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/iphoneplus_splash.png`}
					media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/iphonex_splash.png`}
					media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/iphonexr_splash.png`}
					media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/iphonexsmax_splash.png`}
					media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/ipad_splash.png`}
					media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/ipadpro1_splash.png`}
					media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/ipadpro3_splash.png`}
					media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
					rel="apple-touch-startup-image"
				/>
				<link
					href={`/shared/images/splash/${faviconsFolder}/ipadpro2_splash.png`}
					media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
					rel="apple-touch-startup-image"
				/>
			</Fragment>
		);
	};

	return (
		<Head>
			<title>{metaTitle}</title>
			<meta property="og:title" content={metaTitle} key="title" />
			<meta
				name="og:description"
				content={`${capitalizeKeyword} ${dictionary('on')} ${APP_NAME} ${dictionary('and wow did I strike gold.')}`}
				key="og-description"
			/>
			<meta
				name="description"
				content={`${capitalizeKeyword} ${dictionary('on')} ${APP_NAME} ${dictionary('and wow did I strike gold.')}`}
				key="description"
			/>
			<meta name="keywords" content={`${keywords}`} key="keywords" />
			<meta key="domain-verify" name="p:domain_verify" content="e2fbc79d35752e19304cc84b54e18387" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta
				property="og:image"
				content={`https://www.${fullDomain}/${isHap ? 'fresh/images/hap-logo.png' : 'fresh/images/logo.png'}`}
			/>
			<meta name="theme-color" content="#166bf6" />
			<meta name="application-name" content={APP_NAME} />
			<meta name="msapplication-TileColor" content="#FFFFFF" />
			<meta name="apple-mobile-web-app-title" content={APP_NAME} />
			<meta name="apple-mobile-web-app-capable" content="yes" />

			{pathname === PREFIX_SLUG ? <link rel="canonical" href={`https://www.${fullDomain}${PREFIX}/${slug}`} /> : ''}
			<link rel="preconnect" href={`https://products.${fullDomain}`} />
			<link rel="preconnect" href="https://www.google-analytics.com" />
			<link rel="preconnect" href="https://bat.bing.com" />

			<noscript>
				<img
					height="1"
					width="1"
					style={{ display: 'none' }}
					alt=""
					src="https://ct.pinterest.com/v3/?event=init&tid=2612364800361&pd[em]=<hashed_email_address>&noscript=1"
				/>
			</noscript>
			{renderHeadIcons()}
			{children}
		</Head>
	);
};

export default Helmet;
