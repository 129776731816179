import { useSelector } from 'react-redux';
import { IS_CLIENT } from '../constants/general';
import { getWhoAmI } from '../redux/modules/settings';
import { resolveHost } from '../whoami';

export default function useWhoAmI() {
	let whoami = useSelector(state => getWhoAmI(state.settings));
	if (!whoami && IS_CLIENT) {
        whoami = resolveHost(location.hostname);
	}
    return whoami;
}
