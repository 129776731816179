export const html = `
<h1>About Us</h1>
&nbsp;
<p>
    @@FULL_DOMAIN@@ is a visual comparison search engine, helping you find the best products at the best prices while
    browsing through beautiful images that make online shopping fun.
</p>
<p>
    At @@FULL_DOMAIN@@, we understand that shopping is a visual experience. There&apos;s a reason they say a picture is worth a
    thousand words. We&apos;re visual creatures and most of us process information better when we can see it for ourselves.
</p>
<p>
    That&apos;s why we created a visual product comparison search engine that&apos;s super easy to use. We offer a ton of brands,
    product types and budget ranges. Shopping isn&apos;t supposed to be boring, so we&apos;ve made sure you&apos;ll have fun while
    saving big. Click on a product&apos;s image and you&apos;ll instantly see identical and similar products paired with much
    better deals.
</p>
`;