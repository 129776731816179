import React, { PureComponent } from 'react';
import { withRouter } from 'next/router';
import { EN_LOCALE } from '../constants/locales';

export const getDictionary = router => {
	const { locale, defaultLocale } = router;
	return require(`../../../public/locales/${locale || defaultLocale || EN_LOCALE}/translation.json`);
};

const getHtml = (router, type) => {
	const { locale, defaultLocale } = router;

	// just validation that we have this html pages
	const HTML_TRANSLATIONS = {
		faq: 'faq',
		jobs: 'jobs',
		about: 'about',
		privacy: 'privacy',
		terms: 'terms',
	};

	if (!HTML_TRANSLATIONS[type]) {
		return '';
	}
	return (require(`../../../public/locales/${locale || defaultLocale || EN_LOCALE}/${type}.js`) || {}).html;
};

const getString = (dictionary, value) => {
	const translation = dictionary[value];
	if (!translation) {
		return value;
	}
	return translation;
};

export const getTranslation = (router, value = '') => {
	if (!value) {
		return '';
	}

	const dictionary = getDictionary(router);

	if (typeof value === 'object') {
		// Html Page
		if (!value.type) {
			return '';
		}
		return getHtml(router, value.type);
	}

	// String
	return getString(dictionary, value);
};

export default function withDictionary() {
	return ComposedComponent => {
		class withDictionaryComponent extends PureComponent {
			render() {
				const { router, ...rest } = this.props;
				return <ComposedComponent dictionary={value => getTranslation(router, value)} locale={router.locale} {...rest} />;
			}
		}
		return withRouter(withDictionaryComponent);
	};
}
