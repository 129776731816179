export const html = `
<h1>Privacy Policy</h1>
<p>Last updated: September 10, 2023</p>
<br />
<p>
    This privacy policy (“<b>Privacy Policy</b>”) applies to information collected through Intango Ltd (“<b>we</b>”, “<b>us</b>” or “<b>our</b>”) whether accessed via computer,
    mobile device or other technology (collectively, the “<b>Service</b>” or “<b>Site</b>”).
</p>
<p><b>@@FULL_DOMAIN@@ is an e-commerce website.</b></p>
<p>
    This Privacy Policy discloses what information we may collect through the Service, how such information may be used and/or shared with others, how we safeguard it and how you
    may access and control its use in connection with our marketing communications and activities.
</p>
<p>Please read this Privacy Policy carefully, <b>prior</b> to using the Service, and/or accessing the Site.</p>
<p>
    By using the Service, and/or accessing the Site,
    <span class="italic underline">you are acknowledging that you understand and agree to the terms hereof as well as the Terms of Use set forth on the Site.</span>
</p>

<h2>1. Information we may collect</h2>
<p>
    We only collect and use information we believe is necessary to administer and provide you with the Services on our platform and as required by applicable law and regulations.
</p>
<p>We may collect and maintain both Personal and Non-Personal Information needed for these purposes. As follows:</p>
<p>Information collected directly from your usage and interaction of our Service, is as follows:</p>
<table>
    <thead>
        <tr>
            <th><p>Category</p></th>
            <th><p>Examples</p></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td><p>Online Identifiers</p></td>
            <td><p>User Agent, IP address, Device, Browser, Operating System (standard HTTP header information)</p></td>
        </tr>
        <tr>
            <td><p>Usage Information</p></td>
            <td><p>Preferred Language, Page Served, Clicks, Time, Referring URLs</p></td>
        </tr>
        <tr>
            <td><p>Geolocation</p></td>
            <td><p>Estimation of Geographic location associated with your IP address</p></td>
        </tr>
        <tr>
            <td><p>Other</p></td>
            <td><p>Search Queries</p></td>
        </tr>
        <tr>
            <td></td>
            <td>
                <p>Email address</p>
            </td>
        </tr>
    </tbody>
</table>


<h2>2. How We Collect Your Information</h2>
<p>The data detailed in the table above is collected Automatically, mainly through cookies, pixel tags and local storage.</p>

<h3>Cookies; Managing Cookies/Opt-Out</h3>
<p>
    Cookies are small text files placed on a Device when the Device is used to visit the Service or the Site.
    <b><span class="italic">If you wish to object to collection of information based on our use of cookies, you can disable or delete them.</span></b>
    The methods for doing so varys. You can obtain up-to-date information about blocking and deletion of cookies via the following links:
</p>
<table>
    <thead>
        <tr>
            <th colspan="1" rowspan="1">
                <p>Browser Type</p>
            </th>
            <th colspan="1" rowspan="1">
                <p>Example</p>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td colspan="1" rowspan="1">
                <p>Chrome</p>
            </td>
            <td colspan="1" rowspan="1">
                <p>
                    <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1">
                <p>Firefox</p>
            </td>
            <td colspan="1" rowspan="1">
                <p>
                    <a
                        target="_blank"
                        href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                        >https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a
                    >
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1">
                <p>Opera</p>
            </td>
            <td colspan="1" rowspan="1">
                <p>
                    <a target="_blank" href="https://help.opera.com/en/latest/security-and-privacy">https://help.opera.com/en/latest/security-and-privacy</a>
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1">
                <p>Internet Explorer</p>
            </td>
            <td colspan="1" rowspan="1">
                <p>
                    <a target="_blank" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                        >https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a
                    >
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1">
                <p>Safari</p>
            </td>
            <td colspan="1" rowspan="1">
                <p>
                    <a target="_blank" href="https://support.apple.com/en-il/guide/safari/sfri11471/mac"
                        >https://support.apple.com/en-il/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a
                    >
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="1">
                <p>Edge</p>
            </td>
            <td colspan="1" rowspan="1">
                <p>
                    <a target="_blank" href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies"
                        >https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies</a
                    >
                </p>
            </td>
        </tr>
    </tbody>
</table>

<h3>3rd parties</h3>
<p>
    Our following third-party partners/service providers may also use cookies (or otherwise scripts / web beacons) for the purpose of targeting; tracking and analysing your use of
    the Site or Services; and/or providing you with content:
</p>

<h3>Analytics</h3>
<ul class="dotted-list">
    <li>
        <p>
            <a target="_blank" href="https://tools.google.com/dlpage/gaoptout">Google Analytics</a>
        </p>
    </li>
</ul>
<p>
    You can learn more about how Google uses data it collects via our use of Google Analytics, by visiting Google's Privacy Policy at:
    <a target="_blank" href="https://policies.google.com/technologies/partner-sites">www.google.com/policies/privacy/partners</a>
</p>

<h3>Advertising by third party</h3>
<p>
    We allow certain companies to serve advertisements/ content and collect information within our Site or Services, which may include your personal information. These companies include third party ad servers, ad agencies,
    technology vendors, providers of sponsored content, research firms, as follows (for more information, please click on the selected partner’s name to review its privacy policy
    for our monetization partners we use the services of but not limited to the following list:
</p>
<ul class="dotted-list">
    <li>
        <p>
            <a target="_blank" href="https://policies.google.com/privacy?hl=en#infochoices">Google AdSense</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://privacy.microsoft.com/en-us/privacystatement">Microsoft</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://www.reignn.com/privacy-policy/">ReigNN</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://partnernetwork.ebay.com/page/network-agreement#privacy-notice">eBay</a>
        </p>
    </li>
</ul>
<p>As for our traffic sources advertisement providers we may use the services of the flowing partners but not limited to the following list:</p>
<ul class="dotted-list">
    <li>
        <p>
            <a target="_blank" href="https://about.ads.microsoft.com/en-us/resources/policies/microsoft-advertising-privacy-policy">Bing</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://policies.google.com/privacy?hl=en#infochoices">Google Ads</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://www.facebook.com/privacy">Facebook</a>
        </p>
    </li>

    <li>
        <p>
            <a target="_blank" href="https://www.outbrain.com/legal/privacy#privacy-policy">Outbrain</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://www.zemanta.com/opt-out/">Zemanta</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://www.verizonmedia.com/policies/ie/en/verizonmedia/privacy/topic/b2bprivacypolicy/index.html">Yahoo Gemini</a>
        </p>
    </li>
    <li>
        <p>
            <a target="_blank" href="https://help.pinterest.com/en/article/review-personal-data-options">Pinterest</a>
        </p>
    </li>
</ul>
<p>
    If you wish you may opt-out directly from third-party cookies or other ad-technology trackers through self-regulator services like the Network Advertising Initiative's
    (“<b>NAI</b>”) website – <a href="https://optout.networkadvertising.org/?c=1" target="_blank">NAI consumer opt-out page</a> or the Digital Advertising Alliance's
    (“<b>DAA</b>”) website – <a target="_blank" href="http://www.aboutads.info/">DAA opt-out page.</a>
</p>
<p>
    Please note that blocking all cookies will have a negative impact upon the usability of many websites. If you block cookies, you might not be able to use all the features of
    our Service.
</p>

<h2>3. How We Use Your Information</h2>
<p>We use information that we collect about you for:</p>
<ul class="dotted-list">
    <li>Operating the Site and/or Service.</li>
    <li>Providing you with customized Service experience.</li>
    <li>Internal operation of debugging, support, fraud detection and security.</li>
    <li>Monetization with our third-party partners / service providers.</li>
</ul>

<h2>4. How We Share Your Information</h2>
<p>
    We do not disclose information collected from you with any third-party, other than with – our above listed third-party partners / service providers we use solely for the
    purposes detailed in Section 3, as well with our server company and web hosting service.
</p>
<p>
    We also reserve the right to disclose your information with third parties if we are legally required to do so, to cooperate with law enforcement investigations or other legal
    proceedings, or to protect against misuse or unauthorized use of the Service, solely to the extent needed to enforce our policies and agreements and to investigate potential
    violations thereof.
</p>

<h2>5. How We Protect Your Information</h2>
<p>We maintain tight controls over all the data we collect, retaining it in secured databases with limited and controlled access rights.</p>

<h2>6. How Long We Keep Your Information</h2>
<p>
    We only keep your personal information as long as it is necessary for the purposes set out in this Privacy Policy including for legitimate business purposes and as permitted by
    applicable law. We will only use your information to the extent necessary to comply with our legal obligations, resolve any legal issues, enforce our agreements, and as
    otherwise described in this policy.
</p>
<p>Please note, that we normally cannot trace users, nor can we link you with any unidentifiable information we may have in our database.</p>

<h2 id="data-removal">7. Notice to EU Residents under the ”GDPR”</h2>
<p>
    This section applies to you if you are a national of a European Economic Area (“EEA”) member state. This section describes our good faith effort to meet our obligations under
    the GDPR.
</p>

<h3>Personal Data</h3>
<p>
    The GDPR, defines “personal data” as
    <b
        >“…any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or
        indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to
        the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person”</b
    >
</p>
<p>
    For the purposes of our Privacy Policy, the “data subject” is “you” (“your”, “user”). The personal data we collect may include: inter alia, IP Address and a unique User ID we
    may assign to you, as described in this privacy policy.
</p>
<p>The personal data we collect will only be used and disclosed for the purposes detailed in sections 1 and 2 to this Privacy Policy.</p>
<p>
    Our basis for processing personal data: GDPR Article 6.1(a):
    <span class="italic"
        >“the data subject has given consent to the processing of his or her personal data for one or more specific purposes”; GDPR Article 6.1(b): “processing is necessary for the
        performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract”; GDPR Article
        6(1)(f): “processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party…”</span
    >
</p>

<h3>Your Rights</h3>
<p>Please contact us by submitting an inquiry to the following email privacy@@@FULL_DOMAIN@@, in case you wish to exercise your rights as a GDPR data subject, including:</p>
<ul class="dotted-list">
    <li><p>Request a copy of information, if any, stored about you.</p></li>
    <li><p>Correct information, if any, stored about you.</p></li>
    <li><p>Erase information, if any, stored about you.</p></li>
    <li><p>Restrict how information, if any, about you is used.</p></li>
    <li><p>Transfer the information, if any, about you.</p></li>
    <li><p>Object to how your information, if any, is used in certain cases.</p></li>
    <li><p>Request why we are unable to fulfill your request, if applicable.</p></li>
    <li><p>Lodge a complaint with the competent supervisory authority.</p></li>
</ul>
<p>
    Please note that these rights are not absolute, and requests are subject to any applicable legal requirements, including gambling regulations and other legal and ethical
    reporting or document retention obligations. We may also rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion, in accordance
    with our internal policies.
</p>

<h2>8. Notice to U.S. Residents Under Applicable Privacy Laws</h2>
<p>
    This Section applies to you if you are a natural person/consumer who is aresident of one of the states mentioned in this section, Inter alia, California resident, as defined in
    the applicable laws and regulations.
</p>

<h3>Notice to California Residents Under CCPA/CPRA</h3>
<p>We adopted this section to meet our obligations to comply with the California Consumer Privacy Act (the “CCPA”) and the California Privacy Right Act (the “CPRA”).</p>
<p>Certain terms used in this section have the meanings given to them in the CCPA and the CPRA.</p>

<h3>Personal Information We Collect</h3>
<p>
    We collect “Personal Information” which is defined under the CCPA as:
    <b
        >“…information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular
        consumer or household.”</b
    >
</p>
<p>For the purposes of our Privacy Policy, the “person” is “you” (“your”, “user”).</p>

<h3>Collection and Disclosure</h3>
<p>During the 12-month period prior to the effective date of this Policy, we may have:</p>
<ul class="dotted-list">
    <li>
        <p>Collected the following categories of Personal Information about you:</p>
        <ul class="dotted-list">
            <li><p>Identifiers (personal) (including, for example, name, online and device identifier, IP address, email address, account name and number).</p></li>
            <li><p>Usage Information (including, for example, online browsing and website interaction histories; and direct marketing histories).</p></li>
            <li>
                <p>
                    Computing or mobile-device information and internet or other electronic-network-activity information (including, for example, login credentials; online
                    advertisement engagements; and cookies, tags, and similar device or user identifying information).
                </p>
            </li>
            <li><p>Geolocation information.</p></li>
        </ul>
    </li>
</ul>

<ul class="dotted-list">
    <li>
        <p>Collected Personal Information about you from the following categories of sources:</p>
        <ul class="dotted-list">
            <li><p>You (for example, through your use of our Site).</p></li>
            <li><p>Your computing or mobile devices.</p></li>
            <li><p>Our technology (for example, through observed consumer interactions with us and through our Site).</p></li>
            <li><p>Our services and systems.</p></li>
            <li><p>Our vendors.</p></li>
            <li><p>Public records.</p></li>
            <li><p>Unaffiliated third parties.</p></li>
        </ul>
    </li>
</ul>

<ul class="dotted-list">
    <li>
        <p>
            Collected Personal Information about you for the following business or commercial purposes (supplementing the information described in section 2 above to this Privacy
            Policy):
        </p>
        <ul class="dotted-list">
            <li><p>Operating our Site and services.</p></li>
            <li>
                <p>
                    Auditing related to a current interaction with you and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors,
                    verifying positioning and quality of ad impressions, and auditing compliance.
                </p>
            </li>
            <li>
                <p>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</p>
            </li>
            <li><p>Undertaking internal research for technological development and optimization.</p></li>
            <li><p>Facilitating accounting, auditing, and reporting.</p></li>
            <li>
                <p>
                    Administering technology and ensure technology integrity (including, for example, by maintaining and improving networks; and identifying and fixing problems).
                </p>
            </li>
            <li>
                <p>
                    To fulfill or meet the reason for which you provided the Personal Information (respond to a query, provide you with access to certain functions and features of
                    the Site, for support).
                </p>
            </li>
        </ul>
    </li>
</ul>

<ul class="dotted-list">
    <li>
        <p>Shared your Personal Information for business or commercial purposes, with the following categories of third parties:</p>
        <ul class="dotted-list">
            <li>
                <p>Vendors who provide services on our behalf, including:</p>
                <ol>
                    <li>1. Data center/host/cloud-service provider.</li>
                    <li>2. Vendor (miscellaneous) services.</li>
                </ol>
            </li>
        </ul>
    </li>
</ul>

<ul class="dotted-list">
    <li>
        <p>Disclosed to third parties for a business purpose the following categories of Personal Information about you:</p>
        <ul class="dotted-list">
            <li>
                <p>
                    Identifiers (personal) (including, for example, name, alias, postal address,, online and device identifier, IP address, email address, account name and
                    number,).
                </p>
            </li>
            <li><p>Commercial Information (including, for example, online browsing and website interaction histories; and direct marketing histories).</p></li>
            <li>
                <p>
                    Internet or other electronic-network-activity information (including, for example, online advertisement engagements; cookies, tags, and similar device or user
                    identifying information).
                </p>
            </li>
            <li><p>Geolocation information.</p></li>
        </ul>
    </li>
</ul>

<ul class="dotted-list">
    <li>
        <p>Categories of third parties to whom Personal Information has been disclosed for a business purpose:</p>
        <ul class="dotted-list">
            <li><p>Internet Service Providers.</p></li>
            <li><p>Data Analytics Providers.</p></li>
            <li><p>Operating Systems and Platforms.</p></li>
        </ul>
    </li>
</ul>

<p>In the preceding twelve (12) months, we have not sold Personal Information.</p>

<p>We also do not use or disclose any sensitive Personal Information for purposes other than those authorized by you or as permitted by law.</p>

<p>Your Rights</p>
<p>You may be entitled to the following specific rights under the CCPA and the CPRA regarding your Personal Information:</p>
<p>You have the right to opt out of the “selling” or “sharing” of your Personal Information.</p>
<p>You have the right to opt out of “targeted ads” or “profiling,” as these terms are defined under the privacy laws.</p>
<p>
    You may have the right to request, twice in a 12-month period (depending on the law in your state), the following information about the Personal Information we have collected
    about you during the past 12 months:
</p>
<ul class="dotted-list">
    <li><p>the categories and specific pieces of Personal Information we have collected about you;</p></li>
    <li><p>the categories of sources from which we collected the Personal Information;</p></li>
    <li><p>the business or commercial purpose for which we collected the Personal Information;</p></li>
    <li>
        <p>
            the categories of Personal Information about you that we disclosed for a business purpose, and the categories of third parties to whom we disclosed that information for
            a business purpose.
        </p>
    </li>
</ul>

<p>In addition, you have the right to request that we delete or correct the Personal Information we have collected from you.</p>

<p>
    To help protect your privacy and maintain security, we take steps to verify your identity before granting access to information or complying with a request. To the extent
    permitted by applicable law, we may charge a reasonable fee to comply with your request.
</p>

<p>
    Right to Non-Discrimination or Retaliatory Treatment for Exercise of Privacy Rights: You have the right to exercise your privacy rights without receiving discriminatory or
    retaliatory treatment. If you exercise any of your privacy rights, including the right to restrict the selling or sharing of your Personal Information, you will not be treated
    differently from those who do not exercise their privacy rights.
</p>

<ul class="dotted-list">
    <li><p>Additional Rights and Provisions for Colorado, Connecticut and Virginia Residents</p></li>
</ul>

<p>
    This section applies to Colorado, Connecticut and Virginia Residents as defined by the Colorado Privacy Act (“CPA”), Connecticut Data Privacy Act (“CTDPA”), and Virginia
    Consumer Data Protection Act (“VCDPA”). Capitalized terms defined in the CTDPA, CPA, VCDPA that are used in this section shall have the same meaning as in the CTDPA, CPA and
    VCDPA.
</p>

<p>
    This section describes our collection, use, and disclosure of personal information. You can learn more about the personal information we process, how we process the personal
    information, the purpose for processing such personal information and the categories of third parties with whom we share such data, in Sections 5.2 and 5.3. above.
</p>

<p>We do not sell personal information (as defined under the CTDPA, CPA, VCDPA) for monetary consideration.</p>

<p>
    If you are a Colorado, Connecticut, or Virginia Consumer, subject to certain conditions and restrictions set out in the CTDPA, CPA, VCDPA and other applicable laws, you may
    have certain rights regarding your personal information (as applicable, depending on where you reside), as described below:
</p>

<ul class="dotted-list">
    <li><p>Access the personal information that we may hold about you.</p></li>
    <li><p>Request that we correct inaccuracies in your personal information.</p></li>
    <li><p>Request that we delete personal information that we have collected from or obtained about you.</p></li>
    <li><p>Opt-out of the processing of your personal information for purposes of, targeted advertising or the sale of your personal information.</p></li>
    <li><p>You may have the right to opt-out of the processing of personal information for the purposes of profiling.</p></li>
    <li>
        <p>
            Right to appeal if we refuse to take action on your rights request. Instructions on how to appeal will be provided to you upon such a denial. The appeal should be
            submitted in accordance with section 5.3 below.
        </p>
    </li>
</ul>

<p>Please note that, depending on the nature of your request, you may be asked to provide information to verify your identity before your request can be processed.</p>

<p>You may exercise your privacy rights under the CTDPA, CPA, and VCDPA no more than twice in a 12-month period.</p>

<p>Exercising Your Rights</p>

<p>To exercise the rights described above, please submit your inquiry or request via the following two methods:</p>

<ul class="dotted-list">
    <li><p>Submitting an inquiry to privacy@@@FULL_DOMAIN@@</p></li>
    <li><p>Sending a physical inquiry to Habarzel 38, Tel Aviv, Israel. 6971054</p></li>
</ul>

<p>We will not discriminate against you for exercising any of your CCPA rights.</p>
<p>Please note, we do not explicitly sell your personal information, therefore, there is no need for you to opt-out of the potential sale of your personal information.</p>
<p>
    Only you or a person authorized to act on your behalf, may make a request related to your Information. You may also make a verifiable consumer request on behalf of your minor
    child. If you submit your request through an authorized agent, we may request that the agent provide proof of your prior authorization, as well as information necessary to
    verify your identity.
</p>
<p>
    We can meet sufficiently verifiable user requests. Sufficiently verifiable user requests refer to requests that allow us to reasonably match the information provided by the
    user making the request with the information already available with us, insofar as non-pseudonymous Personal Information is available.
</p>
<p>Any disclosures we provide will only cover the 12-month period preceding receipt of your request.</p>

<h2>9. Messages from us</h2>
<p>
    On occasion we will need to contact you. Primarily, these messages are delivered by email, for a variety of reasons, including marketing, transactions, advocacy, and service
    update purposes. If you no longer wish to receive email marketing, you can opt out of receiving marketing communications via email you can follow the unsubscribe link in any
    marketing email you receive. To ensure you properly receive notifications, we will need to collect certain information about your device, such as operating system and user
    identification information. Every account is required to keep a valid email address on file to receive messages.
</p>
<p>
    When you register for an account, subscribe to a newsletter, or provide us with your email address, you receive notice of and agree (in some jurisdictions and situations, by an
    additional unambiguous consent) to receive marketing emails and messages from us. You can unsubscribe at any time from marketing emails or messages through the opt-out link
    included in marketing emails.
</p>

<h2>10. DATA RETENTION AND DELETION</h2>
<p>We will not retain data longer than is necessary to fulfill the purposes for which it was collected or as required by applicable laws or regulations.</p>
<p>
    We retain identifiable data, which is directly collected for purposes of providing our service to Customers or serving content to end-users, for twenty-four (24) months from
    the end-user’s last interaction with our Site or Service, after which time we will either de-identify the data by removing unique identifiers or aggregating the data, or simply
    delete it.
</p>
<p>We may retain aggregated data, which cannot identify an individual or device and is used for purposes of reporting and analysis, for as long as commercially necessary.</p>

<h2>11. Children</h2>
<p>
    The Service is not directed to children, as defined under applicable laws and specifically under 16 in the EEA or 13 in the U.S.A (<b>“Child”</b>). We do not knowingly collect
    Personal Information from anyone that is considered to be a child. If we determine upon collection that a user is a child , we will not use or maintain his/her Personal
    Information without the parent/guardian’s consent. If we become aware that we have unknowingly collected Personal Information from a child under the age of 13, we will make
    reasonable efforts to delete such information from our records. We encourage parents and legal guardians to be familiar with the Internet activity of their children.
</p>

<h2>12. Security of Your Information</h2>
<p>We maintain tight controls over all the data we collect, retaining it in secured databases with limited and controlled access rights, to ensure it is secure.</p>
<p>
    Please remember that unfortunately, the transmission of information via the internet is not completely secure, so although we will do our best to protect your personal data, we
    cannot guarantee the security of your data transmitted via the Site or Service; any transmission is at your own risk.
</p>
<p>Once we have received your information, we will use procedures and security features to try to prevent unauthorized access.</p>

<h2>13. Consent to Processing and Transfer of Information</h2>
<p>
    Given that we are an international business, our use of your information may involve the transmission of data on an international basis. If you are located in the European
    Union, please be aware that information we collect may be transferred to and processed outside the European Union. By using the Site or Service, or providing us with any
    information, you consent to the collection, processing, maintenance and transfer of such information in and to the United States and other applicable territories in which the
    privacy laws may not be as comprehensive as or equivalent to those in the country where you reside and/or are a citizen.
</p>

<h2>14. Updates to Privacy Policy</h2>
<p>
    Please note, we may modify this Privacy Policy from time to time. You should check back here periodically to see if the Privacy Policy has been updated. We will always show the
    date of the latest modification of the Privacy Policy at the top of the page so you can tell when it was last revised.
</p>

<h2>15. Contact us</h2>
<p>
    We regularly review our compliance with this Privacy Policy. Questions, comments and requests regarding this Privacy Policy are welcomed and should be addressed
    to privacy@@@FULL_DOMAIN@@
</p>

`;
