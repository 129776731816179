import { useEffect, useState } from 'react';

function currentDevice(sizeRules) {
    if (sizeRules.isVeryLargeDesktop) return 'isVeryLargeDesktop';
    if (sizeRules.isLargeDesktop) return 'largeDesktop';
    if (sizeRules.isDesktop) return 'desktop';
    if (sizeRules.isLaptop) return 'laptop';
    if (sizeRules.isTablet) return 'tablet';
    if (sizeRules.isSmallTablet) return 'smallTablet';
    if (sizeRules.isMobile) return 'mobile';
    if (sizeRules.isMinimumSupport) return 'minimumSupport';
    return 'unknown';
}

function resolveSizeRules(width) {
    const devices = {
        isMinimumSupport: width <= 345,
        isMobile: width <= 544,
        isSmallTablet: width > 544 && width <= 768,
        isTablet: width > 768 && width <= 1024,
        isLaptop: width > 1024,
        isDesktop: width > 1140,
        isLargeDesktop: width > 1240,
        isVeryLargeDesktop: width >= 1500
    };

    return {
        ...devices,
        isAllDesktops: devices.isDesktop || devices.isLargeDesktop || devices.isVeryLargeDesktop,
        isComputer: devices.isLaptop || devices.isDesktop || devices.isLargeDesktop || devices.isVeryLargeDesktop,
        isComputerOrTablet: devices.isTablet || devices.isSmallTablet || devices.isLaptop || devices.isDesktop || devices.isLargeDesktop || devices.isVeryLargeDesktop,
    };
}

export const getSize = () => {
    const sizeRules = resolveSizeRules(window.innerWidth);

    return { ...sizeRules, currentDevice: currentDevice(sizeRules) };
};

export function responsiveInit(handleResize, responsive) {
    if (typeof window === 'undefined') return;
    handleResize();

    if (responsive.currentDevice === 'unknown') {
        setTimeout(handleResize);
    }

    window.addEventListener('resize', handleResize);
}

export default function useResponsive() {
    const [responsive, setResponsive] = useState({});

    const handleResize = () => {
        setResponsive(getSize());
    };

    useEffect(() => {
        responsiveInit(handleResize, responsive);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return responsive;
}
