import React, { PureComponent } from 'react';
import QueryManager from 'src/lib/managers/QueryManager';
import { withRouter } from 'next/router';

export default function withParsedQuery() {
	return ComposedComponent => {
		class withParsedQueryComponent extends PureComponent {
			render() {
				const { router, ...rest } = this.props;
				return <ComposedComponent parsedQuery={QueryManager.extractParsedQuery(router)} {...rest} />;
			}
		}
		return withRouter(withParsedQueryComponent);
	};
}
